import React from 'react';

import './ImprovementPill.scss';
import { SubjectDetails } from 'types/types';

interface ImprovementPillProps {
  name: string;
  school: string;
  details: SubjectDetails[];
}

const ImprovementPill: React.FC<ImprovementPillProps> = ({
  name,
  school,
  details,
}) => {
  return (
    <div className="improvement-pill">
      <div className="col student">
        <div className="student-details">
          Student: <span className="name">{name}</span>
        </div>
        <div className="school">{school}</div>
      </div>
      <div className="col improvement">
        {details.map((d, index) => (
          <div key={index} className="improvement-container">
            <div className="subject">{d.subject} </div>
            <div className="before-after">
              {d.before} - {d.after}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImprovementPill;
