import React from 'react';

import Layout from 'components/layout/Layout';
import SEO from 'components/seo';
import ResultsJumbotron from 'components/results-jumbotron/ResultsJumbotron';

const ResultsPage = () => (
  <Layout>
    <SEO title="Student Achievements" />
    <ResultsJumbotron />
  </Layout>
);

export default ResultsPage;
