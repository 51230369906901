import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Img from 'gatsby-image';

import { isMobileMode } from 'utils/app-utils';
import { ImageFileNodes, StudentEdges } from 'types/types';

import './StudentCarousel.scss';

type setStudentIndexFn = React.Dispatch<React.SetStateAction<number>>;
interface StudentCarouselProps {
  studentIndex: number;
  setStudentIndex: setStudentIndexFn;
  studentData: StudentEdges[];
}

const carouselSettingsDesktop = (setStudentIndex: setStudentIndexFn) => ({
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  centerMode: true,
  centerPadding: '0',
  focusOnSelect: true,
  vertical: true,
  verticalSwiping: true,
  beforeChange: (_: number, newIndex: number) => setStudentIndex(newIndex),
});

const carouselSettingsMobile = (setStudentIndex: setStudentIndexFn) => ({
  dots: false,
  arrows: true,
  speed: 500,
  beforeChange: (_: number, newIndex: number) => setStudentIndex(newIndex),
});

const StudentCarousel: React.FC<StudentCarouselProps> = ({
  studentIndex,
  setStudentIndex,
  studentData,
}) => {
  const { studentPicQuery } = useStaticQuery(graphql`
    query {
      studentPicQuery: allFile(
        filter: { relativeDirectory: { regex: "/^(students/images)/" } }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `);

  const studentImages: ImageFileNodes[] = studentPicQuery.nodes;
  const studentImagesObj = studentImages.reduce(
    (acc: { [key: string]: ImageFileNodes }, cur: ImageFileNodes) => {
      acc[cur.childImageSharp.fluid.originalName] = cur;
      return acc;
    },
    {}
  );

  const getStudentImage = (pictureName: string) =>
    studentImagesObj[pictureName].childImageSharp.fluid;

  const generatePictureClassName = (index: number) =>
    studentIndex === index ? 'image-wrapper selected' : 'image-wrapper';

  const carouselSettings = isMobileMode
    ? carouselSettingsMobile(setStudentIndex)
    : carouselSettingsDesktop(setStudentIndex);

  const carouselRef = useRef<Slider>(null);

  const carouselNext = () => carouselRef.current?.slickNext();
  const carouselPrev = () => carouselRef.current?.slickPrev();

  return (
    <div className="student-carousel">
      <button className="carousel-arrow up" onClick={carouselPrev} />
      <Slider ref={carouselRef} {...carouselSettings}>
        {studentData.map((student: StudentEdges, index: number) => {
          const { name, profilePic, school } = student.node.frontmatter;
          return (
            <div
              key={name + index}
              className={generatePictureClassName(index)}
              onClick={() => setStudentIndex(index)}
            >
              <div className="image-pill">
                <Img
                  fluid={getStudentImage(profilePic)}
                  alt={name}
                  className="student-image"
                />
                {isMobileMode && (
                  <div className="mobile-pill-content">
                    <div className="student">Student: {name}</div>
                    <div className="school">{school}</div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
      <button className="carousel-arrow down" onClick={carouselNext} />
    </div>
  );
};

export default StudentCarousel;
