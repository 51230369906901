export const isMobileMode = (() => {
  if (typeof window !== 'undefined') {
    const viewportWidth = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth;

    return viewportWidth <= 768;
  }
  return false;
})();
