import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import {
  ImageFileNodes,
  NonStretchedImageProps,
  StudentEdges,
} from 'types/types';
import ImprovementPill from 'components/improvement-pill/ImprovementPill';
import StudentCarousel from 'components/student-carousel/StudentCarousel';
import { isMobileMode } from 'utils/app-utils';

import './ResultsJumbotron.scss';

enum ESection {
  RESULTS,
  STUDENT,
  PARENT,
}

enum EResultImageOpen {
  BEFORE,
  AFTER,
}

const gatsbyRoot = (() => {
  if (typeof document !== 'undefined') {
    return document.getElementById('___gatsby');
  }
})();

const NonStretchedImage: React.FC<NonStretchedImageProps> = ({
  fluid,
  alt,
  imgStyle,
  onClick,
}) => {
  const normalizedProps = {
    alt,
    imgStyle,
    onClick,
    fluid,
    style: {
      maxWidth: fluid.presentationWidth,
      margin: '0 auto',
      height: '100%',
      width: 'auto',
    },
  };

  return <Img {...normalizedProps} />;
};

const makeTestimonialName = (name: string) =>
  name[name.length - 1] === 's'
    ? `${name}’ Testimonial`
    : `${name}’s Testimonial`;

const ResultsJumbotron = () => {
  const [studentIndex, setStudentIndex] = useState(0);
  const [selectedSection, setSelectedSection] = useState(ESection.STUDENT);
  const [isImageOpen, setImageOpen] = useState(false);
  const [selectedResultImage, setSelectedResultImage] = useState(
    EResultImageOpen.BEFORE
  );

  const { studentDataQuery, studentImagesQuery } = useStaticQuery(graphql`
    query {
      studentDataQuery: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(students)/" } }
      ) {
        edges {
          node {
            frontmatter {
              name
              school
              profilePic
              beforeImg
              afterImg
              testimonial
              parentTestimonial
              details {
                subject
                before
                after
                duration
              }
              order
            }
          }
        }
      }
      studentImagesQuery: allFile(
        filter: { relativeDirectory: { regex: "/^(students/images)/" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              originalName
              presentationWidth
            }
          }
        }
      }
    }
  `);

  const studentData = studentDataQuery.edges.sort(
    (a: StudentEdges, b: StudentEdges) =>
      a.node.frontmatter.order - b.node.frontmatter.order
  );

  const studentImages: ImageFileNodes[] = studentImagesQuery.nodes;
  const studentImagesObj = studentImages.reduce(
    (acc: { [key: string]: ImageFileNodes }, cur: ImageFileNodes) => {
      acc[cur.childImageSharp.fluid.originalName] = cur;
      return acc;
    },
    {}
  );

  const getStudentResultImage = (pictureName: string) =>
    studentImagesObj[pictureName].childImageSharp.fluid;

  const renderSelectedTestimonial = () => {
    switch (selectedSection) {
      case ESection.STUDENT:
        return studentData[studentIndex].node.frontmatter.testimonial;
      case ESection.PARENT:
        return studentData[studentIndex].node.frontmatter.parentTestimonial;
    }
  };

  const renderSelectedHeading = () => {
    switch (selectedSection) {
      case ESection.STUDENT:
        return makeTestimonialName(
          studentData[studentIndex].node.frontmatter.name
        );
      case ESection.PARENT:
        return `Parent of ${studentData[studentIndex].node.frontmatter.name}`;
    }
  };

  const getPillClassName = (section: ESection) =>
    section === selectedSection ? 'selected' : '';

  const openResultImage = (image: EResultImageOpen) => {
    setImageOpen(true);
    setSelectedResultImage(image);
  };

  const closeResultImage = () => setImageOpen(false);

  const desktopImageViewerSettings = {
    objectFit: 'contain',
    height: '100%',
    width: 'auto',
  };

  const mobileImageViewerSettings = {
    objectFit: 'contain',
    height: 'auto',
    width: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const getImageViewer = () =>
    selectedResultImage === EResultImageOpen.BEFORE
      ? studentData[studentIndex].node.frontmatter.beforeImg
      : studentData[studentIndex].node.frontmatter.afterImg;

  const stopPropogation = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="results-jumbotron">
        <div className="left">
          <span className="heading">STUDENT ACHIEVEMENTS</span>
          <div className="student-achievements">
            <div className="carousel">
              <StudentCarousel
                studentIndex={studentIndex}
                setStudentIndex={setStudentIndex}
                studentData={studentData}
              />
              <div className="student-index-count">
                {studentIndex + 1} of {studentData.length}
              </div>
            </div>
            <div className="results-improvement">
              <div className="results-images-wrapper">
                <div
                  className="results-image"
                  onClick={() => openResultImage(EResultImageOpen.BEFORE)}
                >
                  <Img
                    fluid={getStudentResultImage(
                      studentData[studentIndex].node.frontmatter.beforeImg
                    )}
                    alt={studentData[studentIndex].node.frontmatter.beforeImg}
                    className="img"
                  />
                </div>
                <div
                  className="results-image"
                  onClick={() => openResultImage(EResultImageOpen.AFTER)}
                >
                  <Img
                    fluid={getStudentResultImage(
                      studentData[studentIndex].node.frontmatter.afterImg
                    )}
                    alt={studentData[studentIndex].node.frontmatter.afterImg}
                    className="img"
                  />
                </div>
              </div>

              <ImprovementPill
                name={studentData[studentIndex].node.frontmatter.name}
                school={studentData[studentIndex].node.frontmatter.school}
                details={studentData[studentIndex].node.frontmatter.details}
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="testimonial-wrapper">
            <div className="heading-wrapper">
              <span className="student-parent-heading">
                {renderSelectedHeading()}
              </span>
            </div>
            <p
              className="testimonial-content"
              dangerouslySetInnerHTML={{
                __html: renderSelectedTestimonial(),
              }}
            />
          </div>

          <div className="student-parent-pill">
            <div
              className={getPillClassName(ESection.STUDENT)}
              onClick={() => setSelectedSection(ESection.STUDENT)}
            >
              Student
            </div>
            <div
              className={`${getPillClassName(ESection.PARENT)} border-left`}
              onClick={() => setSelectedSection(ESection.PARENT)}
            >
              Parent
            </div>
          </div>
        </div>
      </div>
      {isImageOpen &&
        gatsbyRoot &&
        createPortal(
          <div className="image-viewer" onClick={closeResultImage}>
            <div className="close-icon far fa-times" />
            <NonStretchedImage
              fluid={getStudentResultImage(getImageViewer())}
              alt={getImageViewer()}
              imgStyle={
                isMobileMode
                  ? mobileImageViewerSettings
                  : desktopImageViewerSettings
              }
              onClick={stopPropogation}
            />
          </div>,
          gatsbyRoot
        )}
    </>
  );
};

export default ResultsJumbotron;
